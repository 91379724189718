import moment from 'moment';
import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            formSignup: {
                clinic: {
                    businessDomain: '',
                    ownerName: '',
                    ownerEmail: '',
                    indicative: null,
                    phoneNumber: '',
                    numberCompleted: null,
                    country: null,
                    typeId: null,
                    numberId: '',
                    password: null,
                    confirmPassword: null
                },
                individual: {}
            },
            dashboard: {
                startDate: moment(new Date()).format('YYYY-MM-DD 00:00:00'),
                endDate: moment(new Date()).format('YYYY-MM-DD 23:59:59'),
                appointmentsDoctors: {
                    daySelected: moment(new Date()).format('YYYY-MM-DD'),
                }
            },
            userInfo: null,
            code: '',
            regexPhone: '',
            currentSection: 'monitor',
            currentSectionPerfil: 'clinic cloud plan',
            currentSectionBilling: 'billing overview',
            currentSectionSettingItem: '',
            utc: (new Date().getTimezoneOffset() * -1) / 60,
            currentSessionId: '',
            voiceId: '',
            dataModal: {
                title: '',
                text: '',
                type: '',
                code: '',
                icon: ''
            },
            showInfoModal: false,
            doctorSelected: null,
        }
    },
    getters: {
        getUserInfo: state => state.userInfo,
        getUtc: state => state.utc,
    },
    mutations: {
        updateUserInfo: (state, payload) => state.userInfo = payload,
        updateCode: (state, payload) => state.code = payload,
        updateRegexPhone: (state, payload) => state.regexPhone = payload,
        updateCurrentSection: (state, payload) => state.currentSection = payload,
        updateCurrentSectionBilling: (state, payload) => state.currentSectionBilling = payload,
        updateCurrentSectionPerfil: (state, payload) => state.currentSectionPerfil = payload,
        updateCurrentSectionSettingItem: (state, payload) => state.currentSectionSettingItem = payload,
        updateUtc: (state, payload) => state.utc = payload,
        updateCurrentSessionId: (state, payload) => state.currentSessionId = payload,
        updateVoiceId: (state, payload) => state.voiceId = payload,
        updateSignupClinicIndicative: (state, payload) => state.formSignup.clinic.indicative = payload,
        updateSignupClinicCountry: (state, payload) => state.formSignup.clinic.country = payload,
        updateSignupClinicPhone: (state, payload) => state.formSignup.clinic.phoneNumber = state.formSignup.clinic.phoneNumber += payload,
        updateSignupClinicNumberCompleted: (state, payload) => state.formSignup.clinic.numberCompleted = payload,
        updateSignupTypeId: (state, payload) => state.formSignup.clinic.typeId = payload,
        updateDataModal: (state, payload) => {
            state.dataModal = payload;
            state.showInfoModal = true;
            setTimeout(() => {
                state.showInfoModal = false;
            }, 3000);
        },
    }
});

export default store;